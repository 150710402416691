// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gajananleela-1-js": () => import("./../../../src/pages/gajananleela/1.js" /* webpackChunkName: "component---src-pages-gajananleela-1-js" */),
  "component---src-pages-gajananleela-10-js": () => import("./../../../src/pages/gajananleela/10.js" /* webpackChunkName: "component---src-pages-gajananleela-10-js" */),
  "component---src-pages-gajananleela-11-js": () => import("./../../../src/pages/gajananleela/11.js" /* webpackChunkName: "component---src-pages-gajananleela-11-js" */),
  "component---src-pages-gajananleela-12-js": () => import("./../../../src/pages/gajananleela/12.js" /* webpackChunkName: "component---src-pages-gajananleela-12-js" */),
  "component---src-pages-gajananleela-13-js": () => import("./../../../src/pages/gajananleela/13.js" /* webpackChunkName: "component---src-pages-gajananleela-13-js" */),
  "component---src-pages-gajananleela-14-js": () => import("./../../../src/pages/gajananleela/14.js" /* webpackChunkName: "component---src-pages-gajananleela-14-js" */),
  "component---src-pages-gajananleela-15-js": () => import("./../../../src/pages/gajananleela/15.js" /* webpackChunkName: "component---src-pages-gajananleela-15-js" */),
  "component---src-pages-gajananleela-16-js": () => import("./../../../src/pages/gajananleela/16.js" /* webpackChunkName: "component---src-pages-gajananleela-16-js" */),
  "component---src-pages-gajananleela-17-js": () => import("./../../../src/pages/gajananleela/17.js" /* webpackChunkName: "component---src-pages-gajananleela-17-js" */),
  "component---src-pages-gajananleela-18-js": () => import("./../../../src/pages/gajananleela/18.js" /* webpackChunkName: "component---src-pages-gajananleela-18-js" */),
  "component---src-pages-gajananleela-19-js": () => import("./../../../src/pages/gajananleela/19.js" /* webpackChunkName: "component---src-pages-gajananleela-19-js" */),
  "component---src-pages-gajananleela-2-js": () => import("./../../../src/pages/gajananleela/2.js" /* webpackChunkName: "component---src-pages-gajananleela-2-js" */),
  "component---src-pages-gajananleela-20-js": () => import("./../../../src/pages/gajananleela/20.js" /* webpackChunkName: "component---src-pages-gajananleela-20-js" */),
  "component---src-pages-gajananleela-21-js": () => import("./../../../src/pages/gajananleela/21.js" /* webpackChunkName: "component---src-pages-gajananleela-21-js" */),
  "component---src-pages-gajananleela-3-js": () => import("./../../../src/pages/gajananleela/3.js" /* webpackChunkName: "component---src-pages-gajananleela-3-js" */),
  "component---src-pages-gajananleela-4-js": () => import("./../../../src/pages/gajananleela/4.js" /* webpackChunkName: "component---src-pages-gajananleela-4-js" */),
  "component---src-pages-gajananleela-5-js": () => import("./../../../src/pages/gajananleela/5.js" /* webpackChunkName: "component---src-pages-gajananleela-5-js" */),
  "component---src-pages-gajananleela-6-js": () => import("./../../../src/pages/gajananleela/6.js" /* webpackChunkName: "component---src-pages-gajananleela-6-js" */),
  "component---src-pages-gajananleela-7-js": () => import("./../../../src/pages/gajananleela/7.js" /* webpackChunkName: "component---src-pages-gajananleela-7-js" */),
  "component---src-pages-gajananleela-8-js": () => import("./../../../src/pages/gajananleela/8.js" /* webpackChunkName: "component---src-pages-gajananleela-8-js" */),
  "component---src-pages-gajananleela-9-js": () => import("./../../../src/pages/gajananleela/9.js" /* webpackChunkName: "component---src-pages-gajananleela-9-js" */),
  "component---src-pages-gajananleela-arati-js": () => import("./../../../src/pages/gajananleela/arati.js" /* webpackChunkName: "component---src-pages-gajananleela-arati-js" */),
  "component---src-pages-gajananleela-dhun-js": () => import("./../../../src/pages/gajananleela/dhun.js" /* webpackChunkName: "component---src-pages-gajananleela-dhun-js" */),
  "component---src-pages-gajananleela-mantraprabhav-1-js": () => import("./../../../src/pages/gajananleela/mantraprabhav1.js" /* webpackChunkName: "component---src-pages-gajananleela-mantraprabhav-1-js" */),
  "component---src-pages-gajananleela-mantraprabhav-2-js": () => import("./../../../src/pages/gajananleela/mantraprabhav2.js" /* webpackChunkName: "component---src-pages-gajananleela-mantraprabhav-2-js" */),
  "component---src-pages-gajananleela-namaskar-js": () => import("./../../../src/pages/gajananleela/namaskar.js" /* webpackChunkName: "component---src-pages-gajananleela-namaskar-js" */),
  "component---src-pages-gajananleela-rruna-js": () => import("./../../../src/pages/gajananleela/rruna.js" /* webpackChunkName: "component---src-pages-gajananleela-rruna-js" */),
  "component---src-pages-gajananleela-yethe-js": () => import("./../../../src/pages/gajananleela/yethe.js" /* webpackChunkName: "component---src-pages-gajananleela-yethe-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-naamaanubhava-conclude-js": () => import("./../../../src/pages/naamaanubhava/conclude.js" /* webpackChunkName: "component---src-pages-naamaanubhava-conclude-js" */),
  "component---src-pages-naamaanubhava-cover-js": () => import("./../../../src/pages/naamaanubhava/cover.js" /* webpackChunkName: "component---src-pages-naamaanubhava-cover-js" */),
  "component---src-pages-naamaanubhava-main-js": () => import("./../../../src/pages/naamaanubhava/main.js" /* webpackChunkName: "component---src-pages-naamaanubhava-main-js" */),
  "component---src-pages-naamaanubhava-preface-js": () => import("./../../../src/pages/naamaanubhava/preface.js" /* webpackChunkName: "component---src-pages-naamaanubhava-preface-js" */),
  "component---src-pages-other-dadaanswer-js": () => import("./../../../src/pages/other/dadaanswer.js" /* webpackChunkName: "component---src-pages-other-dadaanswer-js" */),
  "component---src-pages-other-dravyachi-js": () => import("./../../../src/pages/other/dravyachi.js" /* webpackChunkName: "component---src-pages-other-dravyachi-js" */),
  "component---src-pages-other-madhavi-js": () => import("./../../../src/pages/other/madhavi.js" /* webpackChunkName: "component---src-pages-other-madhavi-js" */),
  "component---src-pages-other-paandurang-js": () => import("./../../../src/pages/other/paandurang.js" /* webpackChunkName: "component---src-pages-other-paandurang-js" */),
  "component---src-pages-other-stotra-js": () => import("./../../../src/pages/other/stotra.js" /* webpackChunkName: "component---src-pages-other-stotra-js" */)
}

